import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Choosing whether and when to learn a new programming language is never easy, but there's a few reasons why Zig seems like the right choice for me and may also be for you.`}</p>
    <p>{`Since discovering Casey Muratori's excellent educational materials about game development and software performance, I've been wanting to hone my skills using a system's programming language. Rust has been on my mind for a long time, but I've gotten convinced it's not worth the trade-off (topic for another time.) I felt confident enough to do this using C, but then Zig came along.`}</p>
    <p>{`Zig is a relatively new language, which comes with its own risks. But, it's already used by a few high-profile mission-critical systems like TigerBeetle. Also, the funding behind it is set up as a non-political foundation independent from any company, meaning the developers have total freedom to design and implement the language according to their vision. It also means they have only one clear mission: develop a great programming language.`}</p>
    <p>{`Aside from that, here's what convinced me to use it for my own exploration:`}</p>
    <ul>
      <li parentName="ul">{`It's design goal is to be a better C, not a better C++ like Rust. This means that aside from the simplicity of the language, switching back to C is relatively straight-forward`}</li>
      <li parentName="ul">{`Compared to C, it has a bunch of quality of life features, like array slices, out-of-bounds, memory leak and double free detection and the ability to run code at compile-time which provides template types and more fun stuff without the craziness of C++`}</li>
      <li parentName="ul">{`A standard library that is simple enough to read and understand, with first-class support for custom allocators`}</li>
      <li parentName="ul">{`Excellent cross-compilation support so I can quickly send share what I'm doing with friends using other OSes`}</li>
      <li parentName="ul">{`Great fit with WebAssembly, producing very small binary sizes`}</li>
      <li parentName="ul">{`A strong focus on reducing compilation times, which is a very important factor in the joy of programming!`}</li>
    </ul>
    <p>{`So far, it's been a great experience! I'd say it took me max. 20 hours to get productive and focus on the actual problems I'm solving!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      